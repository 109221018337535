input[type="text"] {
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ed1a1a;
    border-radius: 5px;
    width: 100%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  th, td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
  
  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  button {
    cursor: pointer;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  nav li {
    display: inline-block;
  }
  
  button:hover {
    opacity: 0.8;
  }
  